import { type ICustomImage } from "@filmweb/sanity-types";
import type IFilmklipp from "src/lib/types/Filmklipp";
import { type IFilmomtale } from "src/lib/types/Filmomtale";
import { type ITrailer } from "src/lib/types/Trailer";
import { PortableTextBlock, Reference, SanityDocument, Slug } from "sanity";

import IGallery from "src/lib/types/IGallery";
import ISummaryLine from "src/lib/types/SummaryLine";
import IAuthor from "src/lib/types/Author";


const DocType_Filmnytt = "filmnytt";

export { DocType_Filmnytt };

export default interface IFilmnytt extends SanityDocument {
	_type: "filmnytt";
	title: string;
	sectionPageTitle?: string;
	isStreaming: boolean;
	slug: Slug;
    summary?: ISummaryLine[];
	ingress?: PortableTextBlock[];
	bodytext?: PortableTextBlock[];
	source?: string;
	author?: Reference | IAuthor;
    topGallery?: (Reference | IFilmomtale | IGallery);
	imageV2?: ICustomImage[];

	sectionPageImageV2?: ICustomImage;
	related?: (Reference | ITrailer | IFilmnytt | IFilmomtale | IFilmklipp)[];
	eceId?: number;
	eceSrc?: string;
	eceSrcId?: string;
	firstPublishedAt?: string;
	createdAt?: string;
	updatedAt?: string;
	hideAds?: boolean;
	isAdContent?: boolean;
	wufooFormUrl?: string;
	eceLastUpdated?: string;
	extLink?: string;
	ntbId?: string;
    imageTextTopImage?: string;
}

export function isFilmnytt(document: SanityDocument) : document is IFilmnytt {
    return document._type === DocType_Filmnytt;
}